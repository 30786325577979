import i18n from 'i18next';
import commonEn from './en/common.json';
import commonDe from './de/common.json';
import generalEn from './en/general.json';
import generalDe from './de/general.json';
import startPageEn from './en/startPage.json';
import startPageDe from './de/startPage.json';
import hiveRegisterEn from './en/hiveRegister.json';
import hiveRegisterDe from './de/hiveRegister.json';
import videoEn from './en/video.json';
import videoDe from './de/video.json';
import footerPagesEn from './en/footerPages.json';
import footerPagesDe from './de/footerPages.json';
import footerEn from './en/footer.json';
import footerDe from './de/footer.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        startPage: startPageEn,
        common: commonEn,
        video: videoEn,
        hiveRegister: hiveRegisterEn,
        footerPages: footerPagesEn,
        footer: footerEn,
        general: generalEn,
      },
      de: {
        startPage: startPageDe,
        common: commonDe,
        video: videoDe,
        hiveRegister: hiveRegisterDe,
        footerPages: footerPagesDe,
        footer: footerDe,
        general: generalDe,
      },
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
  });
