import React, { Suspense } from 'react';
import LoadingSpinner from './components/LoadingSpinner';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import client from './utils/apolloClient';

import './i18n/config';
import './App.scss';

const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const AuthGuard = React.lazy(() => import('./components/AuthGuard'));
const NavBar = React.lazy(() => import('./components/NavBar'));
const JoinHive = React.lazy(() => import('./pages/JoinHive'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const Imprint = React.lazy(() => import('./pages/FooterPages/Imprint'));
const ComplaintsPage = React.lazy(() => import('./pages/FooterPages/Complaints/Complaints'));
const DataPrivacy = React.lazy(() => import('./pages/FooterPages/DataPrivacy'));
const TermsAndConditions = React.lazy(() => import('./pages/FooterPages/TermsAndConditions'));

function App(): JSX.Element {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthGuard>
            <ApolloProvider client={client}>
              <ScrollToTop />
              <div className='App'>
                <div className='wrapper'>
                  <NavBar />
                  <div className='bg' />
                  <div className='content'>
                    <Switch>
                      <Route path='/data-privacy'>
                        <DataPrivacy />
                      </Route>
                      <Route path='/imprint'>
                        <Imprint />
                      </Route>
                      <Route path='/t-&-c'>
                        <TermsAndConditions />
                      </Route>
                      <Route path='/complaints'>
                        <ComplaintsPage />
                      </Route>
                      <Route path='/'>
                        <JoinHive />
                      </Route>
                    </Switch>
                    <Footer />
                  </div>
                </div>
              </div>
            </ApolloProvider>
          </AuthGuard>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
}

export default App;
