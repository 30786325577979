import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

// Define a type for the slice state
export interface LoginStateApp {
  value: boolean;
}
//TURN ON AND PUT AS A VALUE IN INITIAL STATE FRO PRODUCTION
function valueInSession(): boolean {
  if (sessionStorage.getItem('login') === 'true') {
    return true;
  } else {
    return false;
  }
}

// Define the initial state using that type
const initialState: LoginStateApp = {
  value: valueInSession(),
};

export const loginSlice = createSlice({
  name: 'login',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logIn: (state) => {
      state.value = true;
      sessionStorage.setItem('login', 'true');
    },
    logOut: (state) => {
      state.value = false;
      sessionStorage.setItem('login', 'false');
    },
  },
});

export const { logIn, logOut } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLogin = (state: RootState): boolean => state.login.value;

export default loginSlice.reducer;
